exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-[id]-js": () => import("./../../../src/pages/[id].js" /* webpackChunkName: "component---src-pages-[id]-js" */),
  "component---src-pages-cafes-[id]-js": () => import("./../../../src/pages/cafes/[id].js" /* webpackChunkName: "component---src-pages-cafes-[id]-js" */),
  "component---src-pages-cafes-index-js": () => import("./../../../src/pages/cafes/index.js" /* webpackChunkName: "component---src-pages-cafes-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-simulador-index-js": () => import("./../../../src/pages/simulador/index.js" /* webpackChunkName: "component---src-pages-simulador-index-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

