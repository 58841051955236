import { createSlice } from '@reduxjs/toolkit'

export const coffeeSlice = createSlice({
  name: 'coffee',
  initialState: '',
  reducers: {
    updateCoffee: (state, action) => {
      return action.payload;
    },
  },
})

export const { updateCoffee } = coffeeSlice.actions

export default coffeeSlice.reducer
