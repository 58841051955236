import { createSlice } from '@reduxjs/toolkit'

export const currentCoffeeSlice = createSlice({
  name: 'currentCoffee',
  initialState: "",
  reducers: {
    updateCurrentCoffee: (state, action) => {
      return action.payload;
    },
  },
})

export const { updateCurrentCoffee } = currentCoffeeSlice.actions

export default currentCoffeeSlice.reducer
