import { configureStore } from '@reduxjs/toolkit'

import userReducer from './reducers/user'
import coffeesReducer from './reducers/coffees'
import coffeeReducer from './reducers/coffee'
import currentCoffeeReducer from './reducers/currentCoffee'
import howToItemsReducer from './reducers/howToItems'
import loadingReducer from './reducers/loading'

export default configureStore({
  reducer: {
    user: userReducer,
    coffees: coffeesReducer,
    coffee: coffeeReducer,
    currentCoffee: currentCoffeeReducer,
    howToItems: howToItemsReducer,
    loading: loadingReducer
  },
})
