import { createSlice } from '@reduxjs/toolkit'

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    show: false,
    message: ''
  },
  reducers: {
    showLoading: (state, action) => {
      state.show = true;
      state.message = action.payload;
    },
    hideLoading: (state) => {
      state.show = false;
      state.message = '';
    }
  },
})

export const { showLoading, hideLoading } = loadingSlice.actions

export default loadingSlice.reducer
