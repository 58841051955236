import { createSlice } from '@reduxjs/toolkit'
import troca from  '../../images/troca.svg'
import mala from  '../../images/mala.svg'
import cafeAcabando from  '../../images/cafe-acabando.svg'
import doJeitoQueVoceGosta from  '../../images/do-jeito-que-voce-gosta.svg'

import video1 from  '../../videos/video1.mp4'
import video2 from  '../../videos/video2.mp4'
import video3 from  '../../videos/video3.mp4'
import video4 from  '../../videos/video4.mp4'

const initialState = [
  {
    id: 1,
    current: true,
    title: 'Você no controle',
    description: 'Escolha os seus próprios cafés alterando a cada envio. Ou deixe no modo aleatório que escolhemos para você.',
    source: video1,
    icon: doJeitoQueVoceGosta
  },
  {
    id: 2,
    title: 'Mudou ou vai viajar?',
    description: 'Altere o endereço de entrega, pule o envio, adicione endereço temporário ou pause a sua assinatura.',
    source: video2,
    icon: mala
  },
  {
    id: 3,
    title: 'O café acabou?',
    description: 'É só adiantar o próximo envio, adicionar mais cafés ou mudar a frequência ',
    source: video3,
    icon: cafeAcabando
  },
  {
    id: 4,
    title: 'Quer mudar qualquer coisa?',
    description: 'Campo de observação - adicione pedidos como: 1 moido, 1 grãos. 1 café escolhido, 1 aleatório.',
    source: video4,
    icon: troca
  }
]

export const howToItemsSlice = createSlice({
  name: 'howToItems',
  initialState: initialState.slice(0),
  reducers: {
    setCurrentItem: (state, action) => {
      state.forEach((item) => {
        item.current = false
      })

      state.forEach((item) => {
        if(item.id === action.payload.id){
          item.current = true
        }
      })      

      return state;
    },
  },
})

export const { setCurrentItem } = howToItemsSlice.actions

export default howToItemsSlice.reducer
